<template>
  <div class="stakeholder-con">
    <div class="stk">
      <div class="stk__item">
        <div>
          <h4>Stakeholder</h4>
        </div>
        <div>
          <h4>Imapct</h4>
        </div>
        <div>
          <h4>Influence</h4>
        </div>
      </div>
    </div>
    <div v-for="x in stakeholders.filter(s => {return s.name != '' })" v-bind:key="x.id" class="stk">
            <div class="stk__item">
              <div class="stakeholder">
               <h4> {{ x.organization}} </h4>
               {{x.group }}
              </div>
              <div>
                <range-slider 
                  class="slider"
                  :min="0"
                  :max="5"
                  :step="1"
                  v-model="x.impact"
                ></range-slider>
              </div>
               <range-slider 
                  class="slider"
                  :min="0"
                  :max="5"
                  :step="1"
                  v-model="x.influence"
                ></range-slider>
            </div>
          </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RangeSlider from "vue-range-component";
import 'vue-range-component/dist/vue-range-slider.css'

export default {
  name: 'Home',
  computed: {
        ...mapGetters(['stakeholders'])
  },
  components: {
     RangeSlider,
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  .stakeholder-con {
    width: 100%;
    .stk {
      background: var(--color-white);
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        margin-top: 0.5rem;
        >div {
          &.stakeholder {
            @include box-bg-white;
          }
          flex: 0 0 30%;
          align-items: center;
        }
      }
    }
  }
</style>
