import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Matrix from '../views/Matrix';
import Start from '../views/Start';
import MapStk from '../views/Map';
import Identify from '../views/Identify';
import Prioritize from '../views/Prioritize'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/start',
    name: 'Start',
    component: Start
  },
  {
    path: '/identify',
    name: 'Identify',
    component: Identify
  },
  {
    path: '/Prioritize',
    name: 'Prioritize',
    component: Prioritize
  },
  {
    path: '/map',
    name: 'Map',
    component: MapStk
  },
  {
    path: '/matrix',
    name: 'Matrix',
    component: Matrix
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
