import { v4 as uuidv4 } from 'uuid';
/* eslint-disable no-debugger */

const state = {
    stakeholders: [{
        id: uuidv4(), 
        name: "Kees Jansen",
        email: "info@voorbeeld.com",
        stype: "Internal",
        group: '',
        organization: "Voorbeeld BV",
        impact: 0,
        influence: 0
    },{
        id: uuidv4(), 
        name: "",
        email: "",
        stype: "",
        group: "",
        organization: "",
        impact: 0,
        influence: 0
    }],
    groups: [
        {
          id: 1,
          name: "Customers",
          stype: "",
          color: "",
        },
        {
          id: 2,
          name: "Employees",
          stype: "",
          color: "",
        },
        {
          id: 3,
          name: "Investors",
          stype: "",
          color: "",
        },
        {
          id: 4,
          name: "Suppliers and Vendors",
          stype: "",
          color: "",
        },
        {
          id: 5,
          name: "Communities",
          stype: "",
          color: "",
        },
        {
          id: 6,
          name: "NGO's",
          stype: "",
          color: "",
        },
    ],
    wizzard: ""
};

const getters = {
    stakeholders: state => state.stakeholders,
    groups: state => state.groups,
    wizzard: state => state.wizzard
    
};

const actions = {
    setWizzard({commit}, model) {
        commit('setWizzard', model );
    },
    createStakeholder({commit}, model) {
        model.id = uuidv4(); 
        commit('setStakeholder', model);
    },
    updateStakeholder({commit, state}, model) {
        debugger;
        const item = state.stakeholders.filter(x => {
            return x.id == model.id
        });

        item[0][model.edited] = model.newValue;

        commit('editStakeholder', item);
        debugger;
    }
};

const mutations = {
    setWizzard: (state, wizzard) => (state.wizzard = wizzard),
    setStakeholder: (state, stakeholder) => {
        state.stakeholders.push(stakeholder)
    },
    editStakeholder: (state, stakeholder) => {
        const currentItem = state.stakeholders.find(x => {return x.id == stakeholder.id})
        currentItem[stakeholder.edited] = stakeholder.newValue;
    }
};

export default {
  state,
  getters,
  actions,
  mutations
}
