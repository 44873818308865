<template>
  <div class="prioritize">
    <div>
      <h3>Identify stakeholder groups</h3>
      <div class="stk__container">
        <div class="stakeholder" v-for="g in groups" v-bind:key="g.id">
          <p>{{g.name}}</p>
          <div class="" v-for="s in stakeholders.filter((x) => {return g.name == x.group })" v-bind:key="s.id">
            {{s.organization}}
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Home',
  computed: {
        ...mapGetters(['groups', 'stakeholders'])
  },
  methods: {
    ...mapActions(['modifyStakeholder']),
  },
  components: {
    
  }
}
</script>

<style scoped lang="scss">

.prioritize {
  width: 100%;
}
.stk__container {
 
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.stakeholder {
  @include box-bg-white;
  flex:0 0 20%;
  margin-right:2rem;
  margin-top: 2rem;
}

</style>