<template>
  <div class="identify">
      <h3>Start with identifying your stakeholders</h3>
      <p>Select the applicable stakeholder groups and start with filling out the organisations within each stakeholder group and their contact details.</p>
      <div class="form-grid">
        <div class="row-form" v-for="(x, i) in stakeholders" v-bind:key="x.id">
          <form class="row" :id="x.id" @submit.prevent="i == stakeholders.length-1 ? addStakeholder : ''" v-on:change="i == stakeholders.length-1 ? addStakeholder():''" >
            
            <v-select label="name" v-model="x.group" :options="groups" name="group" @input="editStakeholderSelect" ></v-select>
            <input autocomplete="nope" type="text" v-model="x.organization" name="organization"  v-on:change="editStakeholder">
            <v-select v-model="x.stype" name="stype" :options="['Internal', 'External', 'Interface']" @input="editStakeholderSelect"></v-select>
            <input autocomplete="nope" type="text" name="name" v-model="x.name" v-on:change="editStakeholder" >
            <input autocomplete="nope" type="email" v-model="x.email" name="email"  v-on:change="editStakeholder">
          </form>
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue'
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
/* eslint-disable no-debugger */

Vue.component('v-select', vSelect)

export default {
  name: 'Home',
  computed: {
        ...mapGetters(['groups', 'stakeholders'])
  },
  methods: {
    ...mapActions(['createStakeholder', 'updateStakeholder']),

    addStakeholder() {
      const newStakeholder = {
        name: this.name,
        email: this.email
      }

      this.createStakeholder(newStakeholder);
      this.name = '';
      this.email = '';
      debugger;
      document.getElementById(newStakeholder.id).focus();
    },
    editStakeholder(e) {
      debugger;
      this.updateStakeholder({
        id: e.currentTarget.closest('.row').id,
        edited: e.currentTarget.name, 
        newValue: e.currentTarget.value
      })
    },
    editStakeholderSelect(e) {
      debugger;
      this.updateStakeholder({
        id: event.currentTarget.closest('.row').id,
        edited: event.currentTarget.closest('.v-select').attributes.name.value, 
        newValue: e.name
      });
      debugger;
    }
  }, 
  components: {
  }
}
</script>

<style lang="scss">
  .identify {
    width: 100%;
  }
  .form-grid {
    margin-top: 5rem;
    max-width: 100%;
    .row {
      display: flex;
      input, .v-select {
          margin-right: 0.5rem;
      }
      >* { 
        
        flex: 0 0 19%; 
      }
    }
  }
</style>
