<template>
  <div class="start">
    <button class="option">
            <h4>Import</h4>
            I already have a list of stakeholders, that I wish to “Drag or drop
            here …. Start manually”
          </button>
          <button class="option" v-on:click="wizzard.nextTab()">
             <h4>Start form scratch</h4>
            I wish to start the process of listing my stakeholders with the use
            of this tool.
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
/* eslint-disable no-debugger */

export default {
  name: 'Home',
  methods: {
    next: function() {
        debugger;
      
    }
  },
  computed: {
        ...mapGetters(['wizzard'])
  }
}
</script>

<style scoped lang="scss">
.start {
    display: flex;
}
</style>