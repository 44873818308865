<template>
  <div class="Matrix"> 
    <h2>Your stakeholder matrix is ready!</h2>
    <div class="flex matrix-tab">
        <div>
          
        </div>
        <div>
          <GChart
            type="BubbleChart"
            :data="chartData"
            :options="chartOptions"
            style="width: 800px; height: 500px"
          />
        </div>
      </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import { GChart } from "vue-google-charts";
import { mapGetters } from 'vuex';
/* eslint-disable no-debugger */


export default {
  name: 'Home',
  components: {
    GChart
  },
  computed: {
        ...mapGetters(['stakeholders'])
  },
  data() {
    debugger;
    let StakholersMapped = [];
    StakholersMapped.push(["ID", "Impact", "Influence", "Stakeholder", "Amount"]);
    this.$store.state.Default.stakeholders.filter(x => {return x.name !== ''}).map(x => {
          StakholersMapped.push([ x.organization, parseFloat(x.impact), parseFloat(x.influence), x.group, 6 ]) 
    })
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: StakholersMapped,
      series: {
        1: { color: "green" },
        2: { color: "green" },
        3: { color: "" },
        4: { color: "" },
        5: { color: "" },
        6: { color: "" },
        7: { color: "" },
        8: { color: "#8E1137" },
        9: { color: "" },
        10: { color: "" },
        11: { color: "" },
        12: { color: "#CD8C32" },
        13: { color: "#477841" },
        14: { color: "" },
        15: { color: "" },
        16: { color: "" },
        17: { color: "#1D3667" },
      },
      chartOptions: {
        title: "Stakeholder Matrix",
        hAxis: {
          title: "Influence",
          viewWindowMode:'explicit',
          viewWindow: {
            max:6,
            min:0
          }
        },
        
        vAxis: { title: "Impact", 
          viewWindowMode:'explicit',
          viewWindow: {
            max:6,
            min:0
          } },
        colors: ["#CD8C32", "#8E1137", "#477841", "#1D3667"],
        bubble: { title: "score", textStyle: { fontSize: 12 } },
      }
    }
  }
}
</script>

<style lang="scss">
  .Matrix {
    width:100%;
    justify-content: center;
    h2 {
      text-align: center;
    }
    .flex {
      justify-content: center;
    }
  }
</style>
