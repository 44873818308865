<template>
  <div id="app">
    <div class="">
      <form-wizard ref="form-wizard" title="" subtitle="">
        <tab-content title="Start" route="/start" icon="ti-user"></tab-content>
        <tab-content title="Select" route="/prioritize" icon="ti-check"></tab-content>
        <tab-content title="Identify" route="/identify" icon="ti-user"></tab-content>
        
        <tab-content title="Prioritize" route="/map" icon="ti-settings"></tab-content>
        <tab-content title="Matrix" route="/matrix" icon="ti-check"></tab-content>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>

      </form-wizard>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

/* eslint-disable no-debugger */

export default {
  name: "App",
  components: {
   
  },
  mounted() {
      this.setWizzard(this.$refs['form-wizard']); 
  },
  methods: {
    ...mapActions(['setWizzard'])
  },
  data() {
    return {
      StakeholdersMap: false,
      
    };
  },
};
</script>

<style lang="scss">
@import '@/scss/main.scss';

button.option {
  padding: 2rem;
  margin:2rem;
  height: 30rem;
  max-width: 30rem;
  &:hover {
    h4 {
      color:var(--color-white)
    }
  }
}

.vue-form-wizard.md {
  
  .wizard-header {
    display: none;
  }
  
  .wizard-card-footer {
    flex: 0 0 100%;
    position: fixed;
    bottom:2rem;
    right: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    justify-self: center;
    align-content: center;
    padding: 2rem;
    .wizard-footer-right {
      button {
        background: var(--color-primary) !important;
      }
    }
    .wizard-footer-left {
      margin-right: 5rem;
      button {
        background: var(--color-primary-light) !important;
      }
    }
  } 
  .wizard-navigation {
    display: flex;
    .wizard-nav {
      flex: 0 0 15rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 2rem;
      height: 100vh;
      li {
        padding: 1rem;
        width: 10rem;
        height: 10rem;
        list-style: none;
        background: var(--color-primary-light);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &.active {
          background: var(--color-primary);
        }
        .stepTitle {
        color:var(--color-white);
          &.active {
            color:var(--color-white)  !important;
          }
        } 
      }
    }
    .wizard-tab-content {
      flex:0 0 calc(100vw - 15rem);
      background: var(--color-white);
      padding: 5rem;
      height: 100vh;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      > .wizard-tab-container {
        display: flex;
        flex-direction: column;
      }
    }
  }
  
}

</style> 
